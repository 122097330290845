import { Injectable, WritableSignal,signal } from '@angular/core';
import { CustomHttpService } from './custom-http.service';
import { Observable } from 'rxjs';
import { Metadata } from '../_models/misc-service.model';
import moment from 'moment';
import { User } from '../_models';

@Injectable({
  providedIn: 'root',
})
export class MetadataService {

  public apiUrl = '';
  public miscMetadata: WritableSignal<Metadata> = signal({
    userDetails: {
      profileName: {
        mandatory: true,
        editable: true,
      },
      genderId: {
        mandatory: true,
        editable: true,
        values: [
          {
            id: 0,
            value: 'string',
          },
        ],
      },
      nationalityId: {
        mandatory: true,
        editable: true,
      },
      dob: {
        mandatory: true,
        editable: true,
      },
      bio: {
        mandatory: true,
        editable: true,
      },
      cityId: {
        mandatory: true,
        editable: true,
      },
      countryId: {
        mandatory: true,
        editable: true,
        values: [
          {
            id: 0,
            value: 'string',
          },
        ],
      },
    },
    contacts: {
      phone: {
        mandatory: true,
        editable: true,
      },
      whatsapp: {
        mandatory: true,
        editable: true,
      },
    },
    userAttributes: {
      height: {
        mandatory: true,
        editable: true,
      },
      weight: {
        mandatory: true,
        editable: true,
      },
      hairColorId: {
        mandatory: true,
        editable: true,
        values: [
          {
            id: 0,
            value: 'string',
          },
        ],
      },
      eyeColorId: {
        mandatory: true,
        editable: true,
        values: [
          {
            id: 0,
            value: 'string',
          },
        ],
      },
      bodyTypeId: {
        mandatory: true,
        editable: true,
        values: [
          {
            id: 0,
            value: 'string',
          },
        ],
      },
      smoking: {
        mandatory: true,
        editable: true,
      },
      tattoo: {
        mandatory: true,
        editable: true,
      },
      piercing: {
        mandatory: true,
        editable: true,
      },
    },
    profilePhoto: {
      mandatory: true,
      editable: true,
    },
    languages: {
      mandatory: true,
      editable: true,
      values: [
        {
          id: 0,
          value: 'string',
        },
      ],
    },
    hidden: {
      mandatory: true,
      editable: true,
    },
    countryCitiesMap: [
      {
        id: 141,
        country: 'Romania',
        cities: [
          {
            id: 1,
            value: '2 mai',
          },
          {
            id: 2,
            value: 'Aiud',
          },
        ],
      },
    ],
  });

  constructor(private customHttpService: CustomHttpService) { }

  getMetadata() {
    return this.customHttpService
      .makeGetRequest(`${this.apiUrl}/misc/app-metadata`, {
        withCredentials: true,
      })
  }

  getSubscriptions(): Observable<any[]> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/public/subscriptions`
    );
  }

  getUserList(sentUserIds: number[], filters: any): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/public/get-users`,
      { sentUserIds, filters }
    );
  }

  getPromotedUserList(sentUserIds: number[], filters: any): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/public/get-promoted-users`,
      { sentUserIds , filters}
    );
  }

  getFavouritesUserList(sentUserIds: number[], filters: any): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/public/get-users?favorite=true`,
      { sentUserIds, filters }
    );
  }

  getYears(dob: number) {
    const b = moment.unix(dob).format('YYYY-MM-DD');
    const years = moment().diff(b, 'years');
    return years;
  }

  getAccount(uuid : string): Observable<any> {
    return this.customHttpService.makeGetRequest(
      `${this.apiUrl}/public/user/${uuid}`,
    );
  }

  addRemoveUserToFav(userId:number): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/public/favorite`,
      { userId }
    );
  }

  toggleFavourite(user: User, event: Event) {
    event.stopPropagation();

    user.favorite = !user.favorite;
    // this.loaderService.showLoading = false;
    this.addRemoveUserToFav(user.userId).subscribe(() => {
      // this.loaderService.showLoading = true;
    });
  }

  updateViewCount(userId:number): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/public/views`,
      { userId }
    );
  }

  updateContactViewCount(userId:number, contactType : 'phone' | 'whatsapp'): Observable<any[]> {
    return this.customHttpService.makePostRequest(
      `${this.apiUrl}/public/contact-view`,
      { userId , contactType}
    );
  }
}
