import { Pipe, PipeTransform } from '@angular/core';
import { MetadataService } from '../_services/metadata.service';

@Pipe({
  name: 'genderPipe', standalone : true
})
export class GenderPipe implements PipeTransform {
  constructor(private metadata: MetadataService) {}
  transform(value: number): any {
    const s = this.metadata.miscMetadata().userDetails.genderId?.values?.find(
      (x) => x.id === value
    )?.value;

    return 'gender.' + s;
  }
}
