import { Pipe, PipeTransform } from '@angular/core';
import { MetadataService } from '../_services/metadata.service';

@Pipe({
  name: 'cityPipe',standalone : true
})
export class CityPipe implements PipeTransform {
  constructor(private metadata: MetadataService) {}
  transform(value: number): any {
    return this.metadata.miscMetadata().countryCitiesMap[0].cities?.find(
      (x: any) => x.id === value
    )?.value;
  }
}
