import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToFixedPipe } from '../_helpers/round-number.pipe';
import { CityPipe } from '../_helpers/city.pipe';
import { GenderPipe } from '../_helpers/gender.pipe';



@NgModule({
  declarations: [],
  imports: [
    ToFixedPipe,
    CityPipe,
    GenderPipe,
  ],
  exports : [ ToFixedPipe, CityPipe, GenderPipe]
   
  
})
export class SharedModule { }
